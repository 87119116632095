import React from 'react';
import Metadata from '@/components/Metadata/Metadata';
import { SOFTQUOTE_METADATA } from '@/components/Metadata/Metadata.constants';
import {RetrieveQuote} from '@/components/PersonalLoanSoftQuote/retrieve/RetrieveQuote';

// TODO: Add the datadog appender
export default props => {
  const hostname = props.location.hostname;
  let component;
  if (hostname === 'quote.latitudefinancial.com.au') {
    component = <RetrieveQuote {...props} />;
  }else if (hostname === 'quote.gemfinance.co.nz') {
    component = <RetrieveQuote {...props} isGem />;
  } else {
    component = <RetrieveQuote {...props} />;
  }
  return (
    <>
      <Metadata {...SOFTQUOTE_METADATA} />
      {component}
    </>
  );
};
